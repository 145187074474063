import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"
const PlCircleFlag = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    viewBox="0 0 512 512"
    {...props}
  >
    <Path
      d="M256 0c141.8 0 256 114.2 256 256H0C0 114.2 114.2 0 256 0z"
      style={{
        fill: "#f9f9f9",
      }}
    />
    <Path
      d="M256 512C114.2 512 0 397.8 0 256h512c0 141.8-114.2 256-256 256"
      style={{
        fill: "#DC143C",
      }}
    />
  </Svg>
)
export default PlCircleFlag
