import React, { useEffect, useState } from "react";
import { ImageProps, Image, View, useWindowDimensions, ImageURISource } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import Zoom from "../zoom/zoom";

export default function FullWidthImage(props: ImageProps) {
    
    const [size, setSize] = useState<{ width: number, height: number }>();

    const [viewDimensions, setViewDimensions] = useState<{ width: number, height: number }>(useWindowDimensions());

    useEffect(() => {
        Image.getSize((props.source as ImageURISource)!.uri!, (w, h) => setSize({ width: w, height: h }));
    }, [props.source]);

    const scaleFactor = size ? size.width / (viewDimensions.width - 2 * 20) : undefined;

    return <>
        <View onLayout={event => setViewDimensions(event.nativeEvent.layout)} accessibilityLabel={props.accessibilityLabel}>
            {!size
                ? <ActivityIndicator /> :
                <Image {...props} resizeMode="cover" progressiveRenderingEnabled={true} style={[{ width: "100%",  height: size.height / scaleFactor! }, props.style]} />}
        </View>
    </>
}