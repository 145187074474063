import * as React from "react"
import Svg, { SvgProps, Path, G, Text, TSpan } from "react-native-svg"
const SmallMonumentIcon = (props: SvgProps & { i: number }) => (
  <Svg
    width={24}
    height={24}
    viewBox="0 0 56.85 57.09"
    style={{ paddingHorizontal: 11.5 }}
    {...props}
  >
    <Path
      d="M28.42 0C16.91 0 7.01 6.84 2.54 16.67h51.75C49.83 6.84 39.93 0 28.42 0Z"
      style={{
        opacity: 0.7,
        fill: "#eb5f41",
        fillRule: "evenodd",
        isolation: "isolate",
      }}
    />
    <Path
      d="M1.25 20.08C.44 22.72 0 25.52 0 28.42s.44 5.7 1.25 8.34H55.6c.81-2.64 1.25-5.44 1.25-8.34s-.44-5.7-1.25-8.34H1.25Z"
      style={{
        fill: "#eb5f41",
        fillRule: "evenodd",
        opacity: 0.85,
        isolation: "isolate",
      }}
    />
    <Path
      d="M54.3 40.17H2.54C7.01 50 16.91 56.84 28.42 56.84S49.83 50 54.3 40.17Z"
      style={{
        fill: "#eb5f41",
        fillRule: "evenodd",
      }}
    />
    <G
      style={{
        isolation: "isolate",
      }}
    >
      <Text
        style={{
          isolation: "isolate",
          fill: "#581c10",
          fontFamily: "Lato-Bold,Lato",
          fontSize: "44.11px",
          fontWeight: 700,
        }}
        transform={props.i > 9 ? "translate(2, 44.52)" : "translate(15.26 44.52)"}
      >
        <TSpan x={0} y={0}>
          {props.i}
        </TSpan>
      </Text>
    </G>
  </Svg>
)
export default SmallMonumentIcon
