import Constants from "expo-constants";
import {ExpoLeaflet, MapMarker} from "expo-leaflet";
import {useEffect, useMemo, useState} from "react";
import {Image, StyleSheet, View} from "react-native";
import {ActivityIndicator, Card, Text} from "react-native-paper";
import {
    EducationPointApi,
    EducationPointDetailsDto,
    EducationPointDto,
    EducationPointPictureInfoDto,
    EducationPointSectionDto,
    MonumentApi,
    MonumentReducedDto,
    TrailApi,
    TrailDto
} from "../../api";
import AudioInvoker from "../../components/audioPlayer/audioInvoker";
import EducationPointCardSmall from "../../components/educationPointCard/educationPointCardSmall";
import FullWidthImage from "../../components/fullWidthImage/fullWidthImage";
import Layout from "../../components/layout/layout";
import TrailsAndPointsMapView from "../../components/mapView/trailsAndPointsMapView";
import {TrailCard} from "../../components/trailCard/trailCard";
import {AudioPlaylistEntry} from "../../contexts/audioContext";
import {takeUntil} from "../../helpers/arrayHelper";
import {getStringForLanguage} from "../../helpers/localizedStringHelpers";
import useApi from "../../hooks/useApi";
import useLanguage from "../../hooks/useLanguage";
import educationalPointDetailsPageString from "./educationalPointDetailsPage.strings";
import EcoIcon from "../../components/icons/ecoIcon"
import L from "leaflet";
import SmallMonumentIcon from "../../components/icons/smallMonumentIcon";

function getAudioUrl(id: number) {
    const baseUrl = Constants.expoConfig!.extra!.apiBaseUrl;

    return `${baseUrl}/api/AudioFile/${id}/FileStream`;
}

function getMonumentIconUrl(id: number) {
    const baseUrl = Constants.expoConfig!.extra!.apiBaseUrl;

    return `${baseUrl}/api/MonumentIcon/${id}/FileStream`;
}

function getSmallMonumentIcon(i: number) {
    return `<?xml version="1.0" encoding="UTF-8"?><svg id="Warstwa_2" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 56.85 57.09"><defs><style>.cls-1-marker{fill:#581c10;font-family:Lato-Bold, Lato;font-size:44.11px;font-weight:700;}.cls-1-marker,.cls-2-marker,.cls-3-marker,.cls-4-marker{isolation:isolate;}.cls-2-marker{opacity:.85;}.cls-2-marker,.cls-3-marker,.cls-5-marker{fill:#eb5f41;fill-rule:evenodd;}.cls-3-marker{opacity:.7;}</style></defs><g id="Warstwa_9"><g><g><path class="cls-3-marker" d="m28.42,0C16.91,0,7.01,6.84,2.54,16.67h51.75C49.83,6.84,39.93,0,28.42,0Z"/><path class="cls-2-marker" d="m1.25,20.08c-.81,2.64-1.25,5.44-1.25,8.34s.44,5.7,1.25,8.34h54.35c.81-2.64,1.25-5.44,1.25-8.34s-.44-5.7-1.25-8.34c0,0-54.35,0-54.35,0Z"/><path class="cls-5-marker" d="m54.3,40.17H2.54c4.47,9.83,14.37,16.67,25.88,16.67s21.41-6.84,25.88-16.67Z"/></g><g class="cls-4-marker"><text class="cls-1-marker" transform="${i > 9 ? "translate(2, 44.52)" : "translate(15.26 44.52)"}"><tspan x="0" y="0">${i}</tspan></text></g></g></g></svg>`
}

export default function EducationalPointDetailsPage(props: { route?: { params: { id: number, trailId?: number } } }) {

    const {language} = useLanguage();
    const pointApi = useApi(EducationPointApi);
    const monumentApi = useApi(MonumentApi);
    const trailApi = useApi(TrailApi);
    const pointId = +props.route!.params.id!;
    const trailId = props.route!.params.trailId ? +props.route!.params.trailId : undefined;
    const [educationPoint, setEducationPoint] = useState<EducationPointDetailsDto>();
    const [monuments, setMonuments] = useState<MonumentReducedDto[]>();
    const [playlist, setPlaylist] = useState<AudioPlaylistEntry[]>();
    const [trails, setTrails] = useState<TrailDto[]>();
    const [allEducationPoints, setAllEducationPoints] = useState<EducationPointDto[]>();

    useEffect(() => {
        setEducationPoint(undefined);

        pointApi.apiEducationPointIdGet(pointId).then(r => {
            setEducationPoint(r.data);
        });

        monumentApi.apiMonumentEducationPointIdGet(pointId).then(r => {
            setMonuments(r.data);
        })
    }, [pointId]);

    useEffect(() => {
        trailApi.apiTrailGet().then(r => setTrails(r.data));
        pointApi.apiEducationPointGet().then(r => setAllEducationPoints(r.data));
    }, []);

    useEffect(() => {
        if (educationPoint) {
            setPlaylist(educationPoint.educationPointSections?.filter(p => p.audio?.length).map(s => ({
                title: getStringForLanguage(educationPoint.name!, language)!,
                subtitle: getStringForLanguage(s.title!, language)!,
                source: {uri: getAudioUrl(s.audio?.filter(a => a.locale === language)[0]?.value!)}
            })));
        }
    }, [language, educationPoint]);

    const largeMonumentCount = monuments?.reduce((a, v) => a + (v.largeVariant ? 1 : 0), 0) || 0;

    const monumentMarkers: MapMarker[] | undefined = useMemo(() => monuments?.sort((a, b) => a.position! - b.position!).map((m, i) => ({
        icon: m.largeVariant ? getMonumentIconUrl(m.monumentIconId!) : getSmallMonumentIcon(i + 1 - largeMonumentCount),
        position: {lat: m.latitude!, lng: m.longitude!},
        id: "monument-" + m.id,
        size: m.largeVariant ? [38, 38] : [24, 24],
        iconAnchor: [8, 36],
        title: getStringForLanguage(m.name!, language) || undefined,
    })), [monuments]);

    return <Layout title={educationalPointDetailsPageString.title[language]}>
        {educationPoint ?
            <View style={{maxWidth: 1000}}>
                <View style={{display: "flex", flexDirection: "row", alignItems:"center", gap: 4, marginBottom: 10}}>
                    <EcoIcon color={trails?.find(t => t.id === trailId)?.color ?? undefined} style={{marginRight: 2}} />
                    <Text variant="headlineSmall"
                        style={{
                            marginBottom: 10,
                            marginTop: 10
                        }}>{getStringForLanguage(educationPoint.name!, language)}</Text>
                </View>
                {(trails && allEducationPoints) ?
                    <TrailsAndPointsMapView style={styles.map} trails={trails} points={allEducationPoints}
                                            focusOnPointId={pointId} additionalMarkers={monumentMarkers}/> :
                    <ActivityIndicator/>}
                {monuments ? monuments.sort((a, b) => a.position! - b.position!).map((m, i) => <View key={m.id} style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 10,
                    marginBottom: 10
                }}>
                    {m.largeVariant ? <Image source={{uri: getMonumentIconUrl(m.monumentIconId!)}}
                           style={{resizeMode: "contain", width: 46, height: 46 }}/> : <SmallMonumentIcon i={i + 1 - largeMonumentCount} />}
                    <Text variant="bodyMedium">{getStringForLanguage(m.name!, language)}</Text>
                </View>) : <ActivityIndicator />}
                <Text variant="titleLarge" style={{ marginTop: 25 }}>{educationalPointDetailsPageString.aboutPoint[language]}</Text>
                {educationPoint?.educationPointSections?.map((s, i) => <EducationalPointSection playlist={playlist!}
                                                                                                audioIndex={i}
                                                                                                point={educationPoint}
                                                                                                section={s}
                                                                                                key={s.id}/>
                )}
                <EducationalPointNextSteps pointId={pointId} trailId={trailId} trails={trails}
                                           points={allEducationPoints}/>
            </View>
            : <ActivityIndicator/>}
    </Layout>;
}

function EducationalPointNextSteps(props: {
    pointId: number,
    trailId?: number,
    trails?: TrailDto[],
    points?: EducationPointDto[]
}) {

    const {language} = useLanguage();

    if (!props.points || !props.trails) {
        return null;
    }

    if (props.trailId) {
        const trail = props.trails.find(t => t.id === props.trailId)!;

        const pointIndex = trail.educationPointsIds!.indexOf(props.pointId);

        const previousPointId = trail.educationPointsIds![pointIndex - 1];
        const previousPoint = props.points.find(p => p.id === previousPointId);

        const nextPointId = trail.educationPointsIds![pointIndex + 1];
        const nextPoint = props.points.find(p => p.id === nextPointId);

        const currentPoint = props.points.find(p => p.id === props.pointId);
        const currentPointLatLng = L.latLng(currentPoint!.latitude!, currentPoint!.longitude!);
        const previousPointLatLng = previousPoint && L.latLng(previousPoint!.latitude!, previousPoint!.longitude!);
        const nextPointLatLng = nextPoint && L.latLng(nextPoint!.latitude!, nextPoint!.longitude!);

        if (previousPoint) {
            console.log("distance from previous", currentPointLatLng.distanceTo(previousPointLatLng!));
        }

        if (nextPoint) {
            console.log("distance to next", currentPointLatLng.distanceTo(nextPointLatLng!));
        }

        return <>
            <Text variant="titleLarge" style={{marginTop: 25}}>{getStringForLanguage(trail.name!, language)}</Text>
            {nextPoint && <>
                <Text variant="labelLarge"
                      style={{marginTop: 10}}>{educationalPointDetailsPageString.nextPoint[language]}</Text>
                <EducationPointCardSmall trailColor={trail?.color ?? undefined} trailId={props.trailId} point={nextPoint} distance={(currentPointLatLng.distanceTo(nextPointLatLng!) / 1000).toPrecision(1)} />
            </>}
            {previousPoint && <>
                <Text variant="labelLarge"
                      style={{marginTop: 10}}>{educationalPointDetailsPageString.previousPoint[language]}</Text>
                <EducationPointCardSmall trailColor={trail?.color ?? undefined} trailId={props.trailId} point={previousPoint} distance={(currentPointLatLng.distanceTo(previousPointLatLng!) / 1000).toPrecision(1)} />
            </>}
        </>
    }

    const trails = props.trails.filter(t => t.educationPointsIds?.includes(props.pointId));

    if (trails.length) {
        return <>
            <Text variant="titleLarge" style={{
                marginTop: 25,
                marginBottom: 10
            }}>{educationalPointDetailsPageString.trailsOnPoint[language]}</Text>
            {trails.map(t => <TrailCard key={t.id} trail={t}/>)}
        </>
    }

    return null;
}

function getPictureUrl(id: number) {
    const baseUrl = Constants.expoConfig!.extra!.apiBaseUrl;

    return `${baseUrl}/api/EducationPointPicture/${id}/FileStream`;
}

function EducationalPointSection(props: {
    point: EducationPointDetailsDto,
    section: EducationPointSectionDto,
    playlist: AudioPlaylistEntry[],
    audioIndex: number
}) {
    const {language} = useLanguage();
    const {point, section} = props;

    const sortedPictures = section.educationPointPicutres?.sort((a, b) => a.position! - b.position!) || [];

    const picturesBefore = takeUntil(sortedPictures, p => p.paragraphsToSkip !== 0).map(p => <PictureWithLabel
        key={p.id} picture={p}/>);

    const picturesAfter = sortedPictures.slice(picturesBefore.length);
    let skippedPhotos = 0;
    let skippedParagraphs = 0;

    const hasAudio = props.playlist?.length > props.audioIndex;

    let content = <>
        {picturesBefore}
        {hasAudio && <AudioInvoker playlist={props.playlist} index={props.audioIndex}/>}
        <Text variant="titleMedium">{getStringForLanguage(section.title!, language)}</Text>
        {getStringForLanguage(section.description!, language)?.split("\n").map((d, i) => {

            let pictures = [];

            while (picturesAfter[skippedPhotos]?.paragraphsToSkip + skippedParagraphs === i) {

                pictures.push(<PictureWithLabel key={skippedPhotos} picture={picturesAfter[skippedPhotos]}/>);

                skippedParagraphs += picturesAfter[skippedPhotos].paragraphsToSkip;
                skippedPhotos++;
            }

            return <View key={i}>
                {pictures}
                <Text key={i} style={{marginTop: 10, textAlign: "justify"}} variant="bodyMedium">
                    {d.replaceAll(/\s([a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ]*\.)/g, "\u00A0$1").replaceAll(/([.,]?\s[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ]{1,3}[.,]?)\s/g, "$1\u00A0").replaceAll(/(\.\s[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ]*)\s/g, "$1\u00A0")}
                    </Text>
            </View>
        })}
    </>

    while (picturesAfter.length > skippedPhotos) {
        content = <>
            {content}
            <PictureWithLabel picture={picturesAfter[skippedPhotos]}/>
        </>;
        skippedPhotos++;
    }

    if (props.playlist && !hasAudio && getStringForLanguage(section.title!, language)) {
        return <Card style={{marginTop: 25}}>
            <Card.Content>
                {content}
            </Card.Content>
        </Card>
    }

    return content;
}

function PictureWithLabel(props: { picture: EducationPointPictureInfoDto }) {
    const {language} = useLanguage();

    return <View style={{marginTop: 15}}>
        <FullWidthImage source={{uri: getPictureUrl(props.picture.id!)}} style={{marginBottom: 5}}
                        accessibilityLabel={getStringForLanguage(props.picture.description!, language) || undefined}/>
        <Text variant="labelSmall">{getStringForLanguage(props.picture.description!, language)}</Text>
    </View>
}

const styles = StyleSheet.create({
    map: {height: 300, marginBottom: 10}
});