import Constants from "expo-constants";
import { Button, Card, Surface } from "react-native-paper";
import { EducationPointDto } from "../../api";
import { getStringForLanguage } from "../../helpers/localizedStringHelpers";
import { Linking, StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import useLanguage from "../../hooks/useLanguage";
import educationPointCardStrings from "./educationPointCard.strings";
import { useNavigation } from "@react-navigation/native";
import EcoIcon from "../icons/ecoIcon";
import { SWTheme } from "../../styles/theme";
import NavigationOutline from "../icons/navigationOutline";

function getCoverPictureUrl(id: number) {
    const baseUrl = Constants.expoConfig!.extra!.apiBaseUrl;

    return `${baseUrl}/api/EducationPointCoverPicture/${id}/FileStream`;
}

export default function EducationPointCard(props: { point: EducationPointDto, trailId?: number, style?: StyleProp<ViewStyle>, trailColor?: string }) {
    const { language } = useLanguage();
    const navigation = useNavigation();

    const openMaps = () => {
        const url = `https://www.google.com/maps/dir/?api=1&destination=${props.point.latitude},${props.point.longitude}`;
        
        Linking.openURL(url!);
    }

    const goToDetails = () => {
        
        if (props.trailId) {
            navigation.navigate("EducationalPointTrailDetails" as never, {
                trailId: props.trailId,
                id: props.point.id! 
            } as never);

            return;
        }

        navigation.navigate("EducationalPointDetails" as never, {
            id: props.point.id!
        } as never);
    }

    return <Card style={[styles.cardWrapper, props.style]}>
        <View>
            {props.point.coverPictureId && <Card.Cover source={{ uri: getCoverPictureUrl(props.point.coverPictureId) }} />}
            <View  style={{ position: 'absolute', bottom: 0, right: 0, backgroundColor: SWTheme.colors.elevation.level1, borderTopLeftRadius: 12 }}>
                <EcoIcon color={props.trailColor} style={{ top: 10, marginTop: 6, marginLeft: 4 }} />
            </View>
        </View>
        <Card.Title titleVariant="titleLarge" title={getStringForLanguage(props.point.name!, language)} />
        <Card.Actions style={{ flexWrap: "wrap", rowGap: 10 }}>
            <Button onPress={openMaps} icon={() => <NavigationOutline />}>{educationPointCardStrings.navigate[language]}</Button>
            <Button onPress={goToDetails}>{educationPointCardStrings.about[language]}</Button>
        </Card.Actions>
    </Card>
}

export const styles = StyleSheet.create({
    cardWrapper: {
        marginVertical: 10,
        maxWidth: 500,
        flexShrink: 1,
        minWidth: 0,
        flexGrow: 1,
    }
});