import useApi from "../../hooks/useApi";
import {EducationPointApi, EducationPointDto, TrailApi, TrailDto} from "../../api";
import {useCallback, useEffect, useState} from "react";
import Layout from "../../components/layout/layout";
import {TrailCard} from "../../components/trailCard/trailCard";
import {ActivityIndicator, BottomNavigation} from "react-native-paper";
import useLanguage from "../../hooks/useLanguage";
import trailListPageStrings from "./trailListPage.strings";
import TrailsAndPointsMapView from "../../components/mapView/trailsAndPointsMapView";
import EducationPointsGrid from "../../components/educationPointsGrid/educationPointsGrid";
import {View, StyleSheet, Text} from "react-native";
import EcoIcon from "../../components/icons/ecoIcon"
import MapMarker from "../../components/icons/mapMarker";
import Map from "../../components/icons/map";
import MapMarkerPath from "../../components/icons/mapMarkerPath";

function TrailList(props: { trails?: TrailDto[] | null }) {
    const {language} = useLanguage();

    return <Layout title={<View style={{flexDirection: 'row', alignItems: 'center'}}>
        <EcoIcon style={{marginRight: 2}}/>
        <Text style={styles.title}>{trailListPageStrings.trailsTitle[language]}</Text>
    </View>} hideBackButton={true}>
        {!props.trails ? <ActivityIndicator/> : props.trails.map(trail => <TrailCard key={trail.id} trail={trail}/>)}
    </Layout>
}

function GeneralMap(props: { trails?: TrailDto[] | null, points?: EducationPointDto[] | null }) {
    const {language} = useLanguage();

    return <Layout title={<View style={{flexDirection: 'row', alignItems: 'center'}}>
        <EcoIcon style={{marginRight: 2}}/>
        <Text style={styles.title}>{trailListPageStrings.map[language]}</Text>
    </View>} hideBackButton={true}>
        {(props.trails && props.points) ?
            <TrailsAndPointsMapView style={{height: "80vh", width: '100vw', maxWidth: 1600}} trails={props.trails}
                                    points={props.points}/> :
            <ActivityIndicator/>}
    </Layout>
}

function PointsList(props: { points?: EducationPointDto[] | null }) {
    const {language} = useLanguage();

    return <Layout title={<View style={{flexDirection: 'row', alignItems: 'center'}}>
        <EcoIcon style={{marginRight: 2}}/>
        <Text style={styles.title}>{trailListPageStrings.pointsTitle[language]}</Text>
    </View>} hideBackButton={true}>
        {!props.points ? <ActivityIndicator/> : <EducationPointsGrid points={props.points}/>}
    </Layout>
}

export default function TrailListPage() {
    const trailsApi = useApi(TrailApi);
    const pointsApi = useApi(EducationPointApi);
    const [trails, setTrails] = useState<TrailDto[] | null>();
    const [points, setPoints] = useState<EducationPointDto[] | null>();
    const {language} = useLanguage();

    const refreshTrailList = useCallback(async () => {
        try {
            const trails = await trailsApi.apiTrailGet();
            setTrails(trails.data);
        } catch (e: any) {
            console.log(e.response?.data);
        }
    }, [trailsApi]);

    const refreshPointsList = useCallback(async () => {
        try {
            const points = await pointsApi.apiEducationPointGet();
            setPoints(points.data);
        } catch (e: any) {
            console.log(e.response?.data);
        }
    }, [pointsApi]);

    useEffect(() => {
        refreshTrailList();
        refreshPointsList();
    }, [refreshTrailList, refreshPointsList]);

    const [index, setIndex] = useState(0);
    const routes = [
        {key: 'trails', title: trailListPageStrings.trails[language], focusedIcon: (p: any) => <MapMarkerPath {...p} />},
        {key: 'map', title: trailListPageStrings.map[language], focusedIcon: (p: any) => <Map {...p} />},
        {key: 'points', title: trailListPageStrings.points[language], focusedIcon: (p: any) => <MapMarker {...p} />},
    ];

    const renderScene = BottomNavigation.SceneMap({
        trails: () => <TrailList trails={trails}/>,
        map: () => <GeneralMap trails={trails} points={points}/>,
        points: () => <PointsList points={points}/>,
    });

    return <BottomNavigation
        sceneAnimationEnabled={true}
        navigationState={{index, routes}}
        onIndexChange={setIndex}
        renderScene={renderScene}
    />
}

const styles = StyleSheet.create({
    title: {
    color: "#201a19",
        fontFamily: "Taviraj_500Medium",
        letterSpacing: 0,
        fontWeight: "400",
        fontSize: 22
}
})
