import {ExpoLeaflet} from "expo-leaflet";
import {View, ViewStyle, StyleProp, StyleSheet} from "react-native";
import React, {useCallback, useState} from "react";
import {LeafletWebViewEvent, MapLayerType, MapMarker, MapShape} from "expo-leaflet/web/src/model";
import {ActivityIndicator, Portal, Modal} from "react-native-paper";
import {useIsFocused, useNavigation} from "@react-navigation/native";
import {LatLng, LatLngBoundsLiteral, MapOptions} from "leaflet";
import {EducationPointDto, TrailDto} from "../../api";
import EducationPointCardSmall from "../educationPointCard/educationPointCardSmall";
import "./Leaflet.fullscreen.min.js";
import "./leaflet.fullscreen.css";

const pointMarker = (isFocused: boolean) => `<?xml version="1.0" encoding="UTF-8"?>
<svg id="Warstwa_1" data-name="Warstwa 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${isFocused ? 52 : 32}" height="${isFocused ? 52: 32}" viewBox="0 0 460.27 650">
  <defs>
    <style>
      .cls-1 {
        fill: #815e58;
        fill-rule: evenodd;
      }

      .cls-2 {
        fill: #815e58;
        fill-rule: evenodd;
        filter: url(#drop-shadow-1);
      }
    </style>
    <filter id="drop-shadow-1" filterUnits="userSpaceOnUse">
      <feOffset dx="29" dy="29"/>
      <feGaussianBlur result="blur" stdDeviation="41"/>
      <feFlood flood-color="#1d1d1b" flood-opacity=".89"/>
      <feComposite in2="blur" operator="in"/>
      <feComposite in="SourceGraphic"/>
    </filter>
  </defs>
  <polygon class="cls-2" points="308.15 400.6 209.27 498 110.39 400.6 308.15 400.6"/>
  <path class="cls-1" d="m376.99,329.82c-5.88,7.76-12.41,15.2-19.56,22.24l-22.41,22.07H83.53l-22.41-22.07c-7.16-7.04-13.68-14.48-19.56-22.24h335.44Z"/>
  <path class="cls-1" d="m159.92,303.38H24.5c-7.65-14.17-13.52-29.07-17.57-44.32h105c11.12,19.07,27.86,34.58,48,44.32m251.69-44.32c-4.06,15.25-9.92,30.14-17.57,44.32h-135.42c20.15-9.73,36.88-25.25,48-44.32h105Z"/>
  <path class="cls-1" style="fill:${isFocused ? "#ea8123" : "#815e58"}" d="m357.43,60.19v.02c47.55,46.84,67.35,111.06,59.4,172.39h-99.16c2.46-9.09,3.76-18.62,3.76-28.45,0-61.01-50.22-110.47-112.16-110.47s-112.16,49.46-112.16,110.47c0,9.84,1.31,19.36,3.76,28.45H1.71c-7.95-61.34,11.85-125.55,59.41-172.39v-.02c81.48-80.25,214.83-80.25,296.32,0"/>
</svg>`;

const mapOptions: MapOptions = {
    maxBounds: [[50.51, 22.0], [50.65, 22.15]] as LatLngBoundsLiteral,
    minZoom: 12,
    zoomSnap: .25,
    preferCanvas: true,
    attributionControl: false,
    fullscreenControl: true
} as any;

export default function TrailsAndPointsMapView(props: TrailsAndPointsMapViewProps) {

    const [detailsModalVisible, setDetailsModalVisible] = useState(false);
    const [modalPointId, setModalPointId] = useState<number>(0);
    const isFocused = useIsFocused();
    const navigation = useNavigation();

    let mapMarkers: MapMarker[] = props.points.map(p => ({
        id: p.id!.toString(),
        icon: pointMarker(p.id == props.focusOnPointId),
        size: [16, 24],
        iconAnchor: [8, 24],
        position: {
            lat: p.latitude!,
            lng: p.longitude!
        }
    }));

    let markersToFit = mapMarkers;

    if (props.additionalMarkers) {
        mapMarkers = mapMarkers.concat(props.additionalMarkers);
    }

    const loadingIndicator = useCallback(() => <ActivityIndicator/>, []);

    const onMessage = useCallback((e: LeafletWebViewEvent) => {
        if (e.tag === "onMapMarkerClicked") {
            setModalPointId(+e.mapMarkerId)
            setDetailsModalVisible(true);
        }
    }, []);

    if (!isFocused) {
        return null;
    }

    const trailShapes: MapShape[] = props.trails.map(trail => ({
        shapeType: "polyline",
        id: trail.id!.toString(),
        color: trail.color!,
        weight: 3,
        smoothFactor: 2,
        dashArray: [10, 10],
        opacity: 0.75,
        positions: trail.track!.map(t => new LatLng(t.latitude!, t.longitude!))
    }));

    let zoom = 12;
    let latitude = 22.053;
    let longitude = 50.582;
    let shouldFitToBounds = true;

    if (props.focusOnPointId) {
        zoom = 16;
        shouldFitToBounds = true;

        const point = props.points.filter(p => p.id === props.focusOnPointId)[0];
        latitude = point.latitude!;
        longitude = point.longitude!;

        if (props.additionalMarkers) {
            markersToFit = [...props.additionalMarkers, { position: { lat: latitude, lng: longitude } } as MapMarker];
        }
    }

    const selectedModalPoint = props.points?.find(p => p.id === modalPointId);

    return <View
        style={[{width: '100%', height: '100%'}, props.style]}>
        <ExpoLeaflet
            loadingIndicator={loadingIndicator}
            mapCenterPosition={{
                lat: latitude,
                lng: longitude
            }}
            zoom={zoom}
            maxZoom={18}
            onMessage={onMessage}
            mapOptions={mapOptions}
            shouldFitToBounds={shouldFitToBounds}
            markersToFit={markersToFit}
            fitToBoundsOptions={{
                paddingBottomRight: [5, 30],
                paddingTopLeft: [20, 40],
            }}
            mapShapes={trailShapes}
            mapMarkers={mapMarkers!}
            mapLayers={[
                {
                    url: "/map/{z}/{x}/{y}.png",
                    layerType: "TileLayer" as MapLayerType,
                    zIndex: 1
                }]
            }
        />
        <Portal>
            {selectedModalPoint && <Modal style={styles.modal} visible={detailsModalVisible}
                                          onDismiss={() => setDetailsModalVisible(false)}>
                <EducationPointCardSmall navigation={navigation} point={selectedModalPoint} onNavigated={() => setDetailsModalVisible(false)} />
            </Modal>}
        </Portal>
    </View>
}

const styles = StyleSheet.create({
    modal: {
        alignSelf: 'center',
        alignItems: 'center',
        marginLeft: 24,
        marginRight: 24,
    }
});

interface TrailsAndPointsMapViewProps {
    style?: StyleProp<ViewStyle>,
    trails: TrailDto[],
    points: EducationPointDto[],
    focusOnPointId?: number,
    additionalMarkers?: MapMarker[]
}
