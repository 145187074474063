import {AudioMode, InterruptionModeAndroid, InterruptionModeIOS, Audio, AVPlaybackStatus} from "expo-av";
import {useContext, useEffect, useState} from "react";
import {AudioActionType, AudioContext, AudioPlaylistEntry} from "../contexts/audioContext";

const audioMode: Partial<AudioMode> = {
    interruptionModeAndroid: InterruptionModeAndroid.DoNotMix,
    interruptionModeIOS: InterruptionModeIOS.DoNotMix,
    playsInSilentModeIOS: true,
    staysActiveInBackground: true,
    shouldDuckAndroid: false,
};

let playersCount = 0;

export default function useAudioPlayer() {
    const {state, dispatch} = useContext(AudioContext);
    const {current, playbackStatus, playlist, playerHidden} = state;
    const [internalIndex, setInternalIndex] = useState(-1);

    const sound = current?.sound;
    const currentIndex = current?.index;

    async function resume() {
        if (current?.sound) {
            await current.sound.playAsync();
        }
    }

    function setPlayerHidden(value: boolean) {
        dispatch({type: AudioActionType.TogglePlayer, value});
    }

    function onPlaybackStatusUpdate(s: AVPlaybackStatus, index: number, playlistLength: number) {
        dispatch({type: AudioActionType.SetStatus, status: s});
        if (!s.isLoaded) {
            return;
        }

        if (s.didJustFinish && (index + 1) < (playlistLength || 0)) {
            setInternalIndex(index + 1);
        }
    }

    useEffect(() => {
        if (internalIndex >= 0) {
            play(internalIndex);
        }
    }, [internalIndex]);

    async function replay() {
        if (sound) {
            await sound.replayAsync();
        }
    }

    async function seek(position: number) {
        await sound?.playFromPositionAsync(position);
    }

    async function play(index: number, p?: AudioPlaylistEntry[]) {
        if (p && p !== playlist) {

            if (current?.sound) {
                await current.sound.unloadAsync();
            }

            dispatch({type: AudioActionType.ClearCurrent});
            dispatch({type: AudioActionType.SetPlaylist, playlist: p});
        }

        if (playerHidden) {
            setPlayerHidden(false);
        }

        if (sound && currentIndex === index) {

            if (playbackStatus?.isLoaded && (playbackStatus.didJustFinish || playbackStatus.durationMillis! - playbackStatus.positionMillis < 500)) {
                await sound.replayAsync();
            }

            await sound.playAsync();

            return;
        }

        if (sound) {
            await sound.unloadAsync();
        }

        Audio.setAudioModeAsync(audioMode);

        const currentPlaylist = p || playlist;

        if (!currentPlaylist) {
            throw Error("Playlist not set");
        }

        const newSound = (await Audio.Sound.createAsync(
                currentPlaylist[index].source,
                {progressUpdateIntervalMillis: 200},
                s => onPlaybackStatusUpdate(s, index, currentPlaylist.length))
        ).sound;

        dispatch({type: AudioActionType.SetSound, sound: newSound, index: index});

        dispatch({type: AudioActionType.SetStatus, status: await newSound.playAsync()});

    }

    async function stop() {
        if (sound) {
            setPlayerHidden(true);
            await sound.stopAsync();
        }
    }

    async function pause() {
        if (sound) {
            await sound.pauseAsync();
        }
    }

    useEffect(() => {
        ++playersCount;

        return () => {
            --playersCount;

            if (playersCount === 0) {
                sound?.unloadAsync();
            }
        }
    }, [sound]);

    useEffect(() => {
        if ((playbackStatus as any)?.didJustFinish && current!.index! + 1 === playlist?.length) {
            setTimeout(() => setPlayerHidden(true), 500);
        }
    }, [playbackStatus])

    return {current, playbackStatus, play, pause, stop, playlist, resume, replay, seek, playerHidden, setPlayerHidden};
}
