const educationalPointDetailsPageString = {
    title: {
        "pl-PL": "Szczegóły przystanku",
        "en-GB": "Educational point"
    },
    map: {
        "pl-PL": "Mapa",
        "en-GB": "Map"
    },
    nextPoint: {
        "pl-PL": "Następny punkt",
        "en-GB": "Next point"
    },
    previousPoint: {
        "pl-PL": "Poprzedni punkt",
        "en-GB": "Previous point"
    },
    trailsOnPoint: {
        "pl-PL": "Szlaki przebiegające przez ten przystanek",
        "en-GB": "Trails that include this educational point"
    },
    aboutPoint: {
        "pl-PL": "O przystanku",
        "en-GB": "About education point"
    }
};

export default educationalPointDetailsPageString;
