import {useCallback, useContext, useEffect} from "react";
import {LanguageContext} from "../contexts/languageContext";
import {getLocales} from 'expo-localization';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default function useLanguage(): { language: "pl-PL" | "en-GB", changeLanguage: (l: "pl-PL" | "en-GB") => void } {
    const {state, dispatch} = useContext(LanguageContext);

    const changeLanguage = useCallback((l: "pl-PL" | "en-GB") => {
        dispatch({language: l});
        AsyncStorage.setItem('language', l);
    }, [dispatch]);

    useEffect(() => {
        if (!state.language) {
            AsyncStorage.getItem('language').then(l => {
                if (l) {
                    dispatch({language: l as any});
                }
            });
        }
    }, []);

    if (state.language) {
        return {language: state.language, changeLanguage};
    }

    const locales = getLocales();

    const plIndex = locales.findIndex(v => v.languageCode === "pl");

    if (plIndex < 0) {
        return {language: "en-GB", changeLanguage};
    }

    const enIndex = locales.findIndex(v => v.languageCode === "en");

    if (enIndex < 0 || plIndex < enIndex) {
        return {language: "pl-PL", changeLanguage};
    }

    return {language: "en-GB", changeLanguage};
}
