// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./fullscreen.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./fullscreen@2x.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".leaflet-control-fullscreen a {\n  background:#fff url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 0 0;\n  background-size:26px 52px;\n  }\n  .leaflet-touch .leaflet-control-fullscreen a {\n    background-position: 2px 2px;\n    }\n  .leaflet-fullscreen-on .leaflet-control-fullscreen a {\n    background-position:0 -26px;\n    }\n  .leaflet-touch.leaflet-fullscreen-on .leaflet-control-fullscreen a {\n    background-position: 2px -24px;\n    }\n\n/* Do not combine these two rules; IE will break. */\n.leaflet-container:-webkit-full-screen {\n  width:100%!important;\n  height:100%!important;\n  }\n.leaflet-container.leaflet-fullscreen-on {\n  width:100%!important;\n  height:100%!important;\n  }\n\n.leaflet-pseudo-fullscreen {\n  position:fixed!important;\n  width:100%!important;\n  height:100%!important;\n  top:0!important;\n  left:0!important;\n  z-index:99999;\n  }\n\n@media\n  (-webkit-min-device-pixel-ratio:2),\n  (min-resolution:192dpi) {\n    .leaflet-control-fullscreen a {\n      background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n    }\n  }\n\n.leaflet-tooltip {\n  background-color: transparent;\n  border: none;\n  box-shadow: none;\n  margin-top: 0px;\n  padding-top: 0px;\n  text-shadow: 0px 1px 8px black;\n}\n\n.leaflet-tooltip::before {\n  content: none;\n}\n\n.leaflet-popup-tip-container {\n  display: none;\n}", ""]);
// Exports
module.exports = exports;
