import * as React from "react"
import Svg, { SvgProps, Defs, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: filter, style */
const SvgComponent = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    id="Warstwa_1"
    width={32}
    height={32}
    data-name="Warstwa 1"
    viewBox="0 0 460.27 650"
    {...props}
  >
    <Defs></Defs>
    <Path
      d="M308.15 400.6 209.27 498l-98.88-97.4h197.76z"
      style={{
        fill: "#815e58",
        fillRule: "evenodd"
      }}
    />
    <Path
      d="M376.99 329.82c-5.88 7.76-12.41 15.2-19.56 22.24l-22.41 22.07H83.53l-22.41-22.07a203.789 203.789 0 0 1-19.56-22.24H377ZM159.92 303.38H24.5a203.028 203.028 0 0 1-17.57-44.32h105c11.12 19.07 27.86 34.58 48 44.32m251.69-44.32a203.73 203.73 0 0 1-17.57 44.32H258.63c20.15-9.73 36.88-25.25 48-44.32h105Z"
      style={{
        fill: props.color || "#815e58",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M357.43 60.19v.02c47.55 46.84 67.35 111.06 59.4 172.39h-99.16a108.72 108.72 0 0 0 3.76-28.45c0-61.01-50.22-110.47-112.16-110.47S97.11 143.14 97.11 204.15c0 9.84 1.31 19.36 3.76 28.45H1.71c-7.95-61.34 11.85-125.55 59.41-172.39v-.02c81.48-80.25 214.83-80.25 296.32 0"
      style={{
        fill: "#815e58",
        fillRule: "evenodd",
      }}
    />
  </Svg>
)
export default SvgComponent
