import React, {useEffect, useState} from "react";
import {View, StyleSheet, Platform, TouchableOpacity} from "react-native";
import {ActivityIndicator, Button, IconButton, Surface, Text} from "react-native-paper";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import useAudioPlayer from "../../hooks/useAudioPlayer";
import {Slider} from '@miblanchard/react-native-slider';
import {SWTheme} from "../../styles/theme";
import {navigationRef} from "../../App";
import PlayCircle from "../icons/playCircle";
import PauseCircle from "../icons/pauseCircle";
import SkipPreviousOutline from "../icons/skipPreviousOutline";
import SkipNextOutline from "../icons/skipNextOutline";
import Close from "../icons/close";

export default function AudioPlayer() {

    const {bottom, left, right} = useSafeAreaInsets();

    const [additionalBottomMargin, setAdditionalBottomMargin] = useState(0);

    const {
        current,
        resume,
        playbackStatus,
        pause,
        stop,
        playlist,
        play,
        replay,
        seek,
        playerHidden
    } = useAudioPlayer();

    const [isLoading, setIsLoading] = useState<boolean>();

    useEffect(() => {
        if (playbackStatus?.isLoaded && (!playerHidden || playbackStatus.isPlaying)) {
            return navigationRef?.addListener("state", () => {
                const routes = navigationRef.getState().routes;

                if (routes[routes.length - 1].name === "TrailList") {
                    setAdditionalBottomMargin(80);
                } else {
                    setAdditionalBottomMargin(0);
                }
            });
        } else {
            setAdditionalBottomMargin(0);
        }
    }, [playbackStatus?.isLoaded && playbackStatus.isPlaying]);

    if (current?.index === undefined || !playlist) {
        return null;
    }

    const onPlayButtonPress = async () => {
        setIsLoading(true);

        try {
            await resume();
        } finally {
            setIsLoading(false);
        }
    }

    const playPrevious = async () => {
        setIsLoading(true);

        try {
            if (playbackStatus?.isLoaded) {
                if (playbackStatus.positionMillis > 2000) {
                    await replay();
                    return;
                }
            }
            await play(current.index! - 1);
        } finally {
            setIsLoading(false);
        }
    }

    const playNext = async () => {
        setIsLoading(true);

        try {
            await play(current.index! + 1);
        } finally {
            setIsLoading(false);
        }
    }

    const playButton = (() => {

        if (isLoading || (playbackStatus?.isLoaded && playbackStatus.isBuffering && playbackStatus.shouldPlay)) {
            return <View style={{width: 48, height: 48, alignItems: "center", justifyContent: "center"}}>
                <ActivityIndicator/>
            </View>;
        }

        if (!playbackStatus?.isLoaded || !playbackStatus?.isPlaying) {
            return <IconButton icon={p => <PlayCircle {...p} />} onPress={onPlayButtonPress} accessibilityLabel="Wznów odtwarzanie"
                               size={48} style={{width: 48, height: 48, margin: 0}}/>;
        }

        if (playbackStatus.isPlaying) {
            return <IconButton icon={p => <PauseCircle {...p} />} onPress={pause} accessibilityLabel="Wstrzymaj odtwarzanie" size={48}
                               style={{width: 48, height: 48, margin: 0}}/>;
        }
    })();

    const onSeek = async (value: number) => {
        await seek(Math.round(value));
    }

    const progress = playbackStatus?.isLoaded ? playbackStatus.positionMillis : 0;
    const currentPlaylistElement = playlist[current.index];
    const {title, subtitle} = currentPlaylistElement;
    const isPreviousDisabled = (current.index || 0) === 0 && (!playbackStatus?.isLoaded || playbackStatus.positionMillis <= 2000);
    const isNextDisabled = current.index + 1 >= playlist.length;

    return <Surface elevation={1}
                    style={{
                        bottom: bottom + additionalBottomMargin,
                        left,
                        right,
                        position: Platform.OS === "web" ? "fixed" : "relative"
                    } as any}>
        {(!playerHidden || !playbackStatus?.isLoaded || playbackStatus.isPlaying) &&
            <View style={[styles.container]}>
                <ProgressSlider trackClickable={false} progress={progress}
                                maximum={playbackStatus?.isLoaded ? playbackStatus?.durationMillis || Number.MAX_VALUE : Number.MAX_VALUE}
                                onSeek={onSeek}/>
                <View style={[styles.content]}>
                    <View style={styles.description}>
                        <Text numberOfLines={1} variant="titleSmall">{subtitle}</Text>
                        <Text numberOfLines={1} style={styles.title}>{title}</Text>
                    </View>
                    <View style={styles.buttonsContainer}>
                        <IconButton icon={p => <SkipPreviousOutline {...p} />} onPress={playPrevious} disabled={isPreviousDisabled}
                                    size={36}/>
                        {playButton}
                        <IconButton icon={p => <SkipNextOutline {...p} />} onPress={playNext} disabled={isNextDisabled} size={36}/>
                        <IconButton icon={p => <Close {...p} />} onPress={stop}/>
                    </View>
                </View>
            </View>}
    </Surface>
}

function ProgressSlider({
                            progress,
                            maximum,
                            onSeek,
                            trackClickable
                        }: {
    progress: number,
    maximum: number,
    onSeek: (v: number) => Promise<any>,
    trackClickable: boolean
}) {

    const [isSliding, setIsSliding] = useState(true);
    const [slidingValue, setSlidingValue] = useState(0);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsSliding(false);
        }, 0);

        return () => clearTimeout(timeout);
    }, []);

    const onValueChange = async (value: number) => {
        setIsSliding(false);
        await onSeek(value)
    }

    return <Slider
        trackClickable={trackClickable}
        value={isSliding ? slidingValue : progress}
        containerStyle={styles.slider}
        maximumValue={maximum}
        animateTransitions={true}
        minimumTrackTintColor={SWTheme.colors.secondary}
        maximumTrackTintColor={SWTheme.colors.secondaryContainer}
        thumbTintColor={SWTheme.colors.secondary}
        thumbTouchSize={{height: 20, width: 40}}
        renderAboveThumbComponent={() => isSliding ?
            <Text style={styles.sliderLabel} variant="labelSmall">{formatMillis(slidingValue)}</Text> : null}
        onValueChange={v => setSlidingValue(v as number)}
        onSlidingStart={() => setIsSliding(true)}
        onSlidingComplete={v => onValueChange(v as number)}
        thumbStyle={{width: 12, height: 12, display: isSliding ? "flex" : "none"}}
        trackStyle={{borderRadius: 0}}
    />;
}

function formatMillis(input: number) {
    const seconds = Math.round(input / 1000);

    return `${Math.floor(seconds / 60)}:${(seconds % 60).toString().padStart(2, "0")}`;
}

const styles = StyleSheet.create({
    container: {
        width: "100%",
        backgroundColor: SWTheme.colors.surface
    },
    buttonsContainer: {
        flexDirection: "row",
        alignItems: "center",
        paddingLeft: 32
    },
    description: {
        justifyContent: "center",
        flexShrink: 1
    },
    content: {
        flexDirection: "row",
        paddingHorizontal: 24,
        paddingVertical: 5,
        justifyContent: "space-between"
    },
    title: {
        fontFamily: "Inter_500Medium",
        fontSize: 11,
        lineHeight: 16,
        letterSpacing: .5,
        textTransform: "uppercase"
    },
    sliderLabel: {
        marginLeft: -5,
        marginBottom: 10
    },
    slider: {
        position: "absolute",
        top: -10,
        zIndex: 1,
        width: "100%",
        height: 20
    }
});
