const layoutStrings = {
    dialogTitle: {
        "pl-PL": "Język aplikacji",
        "en-GB": "App display language"
    },
    cancelButton: {
        "pl-PL": "Anuluj",
        "en-GB": "Cancel"
    },
    backAction: {
        "pl-PL": "Wstecz",
        "en-GB": "Go back"
    },
    changeLanguage: {
        "pl-PL": "Zmień język",
        "en-GB": "Change language"
    }
};

export default layoutStrings;