const educationPointCardStrings = {
    navigate: {
        "pl-PL": "Nawiguj",
        "en-GB": "Directions"
    },
    about: {
        "pl-PL": "O przystanku",
        "en-GB": "About point"
    }
};

export default educationPointCardStrings;