const trailDetailsPageStrings = {
    about: {
        "pl-PL": "O szlaku",
        "en-GB": "About trail"
    },
    points: {
        "pl-PL": "Przystanki na szlaku",
        "en-GB": "Educational points along the trail"
    },
    map: {
        "pl-PL": "Mapa",
        "en-GB": "Map"
    }
};

export default trailDetailsPageStrings;