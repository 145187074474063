const trailListPageStrings = {
    trailsTitle: {
        "pl-PL": "Szlaki turystyczne",
        "en-GB": "Tourist trails"
    },
    trails: {
        "pl-PL": "Szlaki",
        "en-GB": "Trails"
    },
    map: {
        "pl-PL": "Mapa",
        "en-GB": "Map"
    },
    pointsTitle: {
        "pl-PL": "Przystanki Naszej Historii",
        "en-GB": "Our History Education Points"
    },
    points: {
        "pl-PL": "Przystanki",
        "en-GB": "Points"
    }
};

export default trailListPageStrings;