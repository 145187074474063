import { useState } from "react";
import { View } from "react-native";
import { EducationPointDto } from "../../api";
import EducationPointCard from "../educationPointCard/educationPointCard";

export default function EducationPointsGrid(props: { points: EducationPointDto[], trailId?: number, trailColor?: string }) {

    const [width, setWidth] = useState<number>();

    const onLayout = (width: number) => {
        if (width >= 500) {
            setWidth(500);
        }
    }

    return <View onLayout={e => onLayout(e.nativeEvent.layout.width)} style={{ flexDirection: 'row', flexWrap: 'wrap', gap: 20, justifyContent: 'center' }}>
        {props.points.map(p => <EducationPointCard key={p.id} point={p} style={width ? ({ width: width }) : undefined} trailId={props.trailId} trailColor={props.trailColor} />)}
    </View>;
}