import {useNavigation} from "@react-navigation/native";
import React, {ReactElement, ReactNode, useEffect, useState} from "react";
import {ScrollView, StyleSheet, View} from "react-native";
import {Appbar, Button, Dialog, Portal, RadioButton, Text} from "react-native-paper";
import useLanguage from "../../hooks/useLanguage";
import layoutStrings from "./layout.strings";
import PlCircleFlag from "../icons/plCircleFlag";
import EnCircleFlag from "../icons/enCircleFlag";
import { Helmet } from "react-helmet";

export default function Layout(props: LayoutProps) {

    const {language} = useLanguage();
    const [dialogVisible, setDialogVisible] = useState(false);
    const navigation = useNavigation();

    const onGoBack = () => {

        if (navigation.canGoBack()) {
            navigation.goBack();

            return;
        }

        window.history.back();
    }

    return <>
        <Helmet>
            <html lang={language} />
        </Helmet>
        <Appbar.Header>
            {!props.hideBackButton &&
                <Appbar.BackAction onPress={onGoBack} accessibilityLabel={layoutStrings.backAction[language]}/>}
            <Appbar.Content mode="center-aligned" title={props.title}/>
            <Appbar.Action animated={false} icon={() => language === "pl-PL" ? <PlCircleFlag width={32} height={32} /> : <EnCircleFlag width={32} height={32} />} accessibilityLabel={layoutStrings.changeLanguage[language]} onPress={() => setDialogVisible(true)}/>
        </Appbar.Header>
        <LanguageDialog isVisible={dialogVisible} onDismiss={() => setDialogVisible(false)}/>
        <ScrollView style={{paddingHorizontal: 20, paddingTop: 5, paddingBottom: 115}}
                    contentContainerStyle={{alignItems: 'stretch', flexDirection: 'row'}}>
            <View style={{flex: 1}}/>
            <View style={{maxWidth: 1600, flexShrink: 1}}>
                {props.children}
            </View>
            <View style={{flex: 1}}/>
        </ScrollView>
    </>
}

export function LanguageDialog(props: { isVisible: boolean, onDismiss: () => any }) {
    const {language, changeLanguage} = useLanguage();
    const [internalLang, setInternalLang] = useState(language);

    useEffect(() => {
        if (language !== internalLang) {
            setInternalLang(language);
        }
    }, [language]);

    const onOk = () => {
        changeLanguage(internalLang);
        props.onDismiss();
    };

    return <Portal>
        <Dialog visible={props.isVisible} onDismiss={props.onDismiss}>
            <Dialog.Title>{layoutStrings.dialogTitle[internalLang]}</Dialog.Title>
            <Dialog.Content>
                <RadioButton.Group onValueChange={setInternalLang as any} value={internalLang}>
                    <View style={styles.radioRow}>
                        <RadioButton.Android value="pl-PL" accessibilityLabel="Polski"/>
                        <PlCircleFlag width={24} height={24} />
                        <Text onPress={() => setInternalLang("pl-PL")} variant="bodyLarge">Polski</Text>
                    </View>
                    <View style={styles.radioRow}>
                        <RadioButton.Android value="en-GB" accessibilityLabel="English"/>
                        <EnCircleFlag width={24} height={24} />
                        <Text onPress={() => setInternalLang("en-GB")} variant="bodyLarge">English</Text>
                    </View>
                </RadioButton.Group>
            </Dialog.Content>
            <Dialog.Actions>
                <Button onPress={props.onDismiss}>{layoutStrings.cancelButton[internalLang]}</Button>
                <Button onPress={onOk}>Ok</Button>
            </Dialog.Actions>
        </Dialog>
    </Portal>
}

const styles = StyleSheet.create({
    radioRow: {
        flexDirection: "row",
        alignItems: "center",
        gap: 10
    }
});

interface LayoutProps {
    canGoBack?: boolean,
    title: string | ReactElement,
    children: ReactNode,
    hideBackButton?: boolean
}
