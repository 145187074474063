const welcomePageStrings = {
    beginTour: {
        "pl-PL": "Rozpocznij zwiedzanie",
        "en-GB": "Begin the tour"
    },
    footer: {
        "pl-PL": "Projekt pt. „MODELOWE ROZWIĄZANIA NA TRUDNE WYZWANIA - Plan Rozwoju Lokalnego i Instytucjonalnego Stalowej Woli” współfinansowany jest ze środków Norweskiego Mechanizmu Finansowego 2014-2021 (85%) oraz Budżetu Państwa (15%)",
        "en-GB": "Project named „MODEL SOLUTIONS FOR DIFFICULT CHALLENGES – Local and Institutional Development Plan for Stalowa Wola” is co-financed from the funds of the Norwegian Financial Mechanism 2014 - 2021 (85%) and of the State Budget (15%)"
    },
    close: {
        "pl-PL": "Zamknij",
        "en-GB": "Close"
    },
    toDownload: {
        "pl-PL": "Pliki dostępne do pobrania",
        "en-GB": "Files available to download"
    },
    stateFunds: {
        "pl-PL": "Dofinansowanie ze środków Budżetu Państwa",
        "en-GB": "State Budget co-financing"
    },
    norwayGrants: {
        "pl-PL": "Dofinansowanie ze środków Norweskiego Mechanizmu Finansowego",
        "en-GB": "Norwegian Financial Mechanism co-financing"
    }
};

export default welcomePageStrings;