import * as React from "react"
import Svg, {SvgProps, Path} from "react-native-svg"
import {memo} from "react"

const PlFlag = (props: SvgProps) => (
    <Svg
        viewBox="0 0 8 5"
        {...props}
    >
        <Path fill="#dc143c" d="M0 0h8v5H0z"/>
        <Path fill="#fff" d="M0 0h8v2.5H0z"/>
    </Svg>
)

const Memo = memo(PlFlag)
export default Memo
