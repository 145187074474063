import {useNavigation} from "@react-navigation/native";
import {FlatList, Linking, Modal, TouchableOpacity, View} from "react-native";
import {Button, Text, TouchableRipple} from "react-native-paper";
import React, {useState} from "react";
import NorwayGrants from "../components/icons/norwayGrants";
import PlFlag from "../components/icons/plFlag";
import PlIcon from "../components/icons/plIcon";
import SwIcon from "../components/icons/swIcon";
import MuseumLogo from "../components/icons/museumLogo";
import MuseumLogoOnly from "../components/icons/museumLogoOnly";
import Icon from "react-native-paper/src/components/Icon";
import { LanguageDialog } from "../components/layout/layout";
import useLanguage from "../hooks/useLanguage";
import PlCircleFlag from "../components/icons/plCircleFlag";
import EnCircleFlag from "../components/icons/enCircleFlag";
import welcomePageStrings from "./welcomePage.strings";
import { Helmet } from "react-helmet";

export default function WelcomePage() {
    const {language} = useLanguage();
    const [dialogVisible, setDialogVisible] = useState(false);
    const navigation = useNavigation();
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    return <>
        <Helmet>
            <html lang={language} />
        </Helmet>
        <TouchableRipple style={{ alignSelf: "flex-end", width: 40, height: 40, marginTop: 16, marginRight: 6 }} onPress={() => setDialogVisible(true)}>
            {language === "pl-PL" ? <PlCircleFlag width={32} height={32} /> : <EnCircleFlag width={32} height={32} />}
        </TouchableRipple>
        <LanguageDialog isVisible={dialogVisible} onDismiss={() => setDialogVisible(false)}/>
        <View style={{gap: 15, margin: 20, alignItems: "stretch", justifyContent: "space-between", flex: 1}}>
            <MuseumLogoOnly style={{marginVertical: 50}}/>
            <Text variant="displaySmall" style={{textAlign: "center"}}>Przystanki Naszej Historii</Text>
            <Text variant="headlineMedium" style={{textAlign: "center"}}>Stalowa Wola</Text>

            <View style={{flexDirection: 'row', justifyContent: 'center'}}>
                <Button mode="contained" onPress={() => navigation.navigate("TrailList" as never)}
                        style={{marginVertical: 30, maxWidth: 1450, flex: 1}}>
                    {welcomePageStrings.beginTour[language]}
                </Button>
            </View>
            <View style={{flexDirection: 'row', justifyContent: 'center'}}>
                <TouchableOpacity style={{maxWidth: 1450, flex: 1}} onPress={() => setModalVisible(true)}>
                    <Text variant="bodySmall" style={{textAlign: "center"}}>
                        {welcomePageStrings.footer[language]}
                    </Text>
                    <View
                        style={{
                            height: 50,
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginTop: 25
                        }}>
                        <NorwayGrants style={{height: 50}}/>
                        <PlFlag style={{height: 40, alignSelf: "center", marginHorizontal: 10}}/>
                        <PlIcon style={{height: 45, alignSelf: "center", marginHorizontal: 10}}/>
                        <SwIcon style={{height: 50, marginHorizontal: 10}}/>
                        <MuseumLogo style={{height: 35, alignSelf: "center"}}/>
                    </View>
                </TouchableOpacity>
            </View>
        </View>
        <Modal
            animationType="slide"
            transparent={false}
            onRequestClose={() => setModalVisible(false)}
            visible={modalVisible}
        >
            <View style={{gap: 15, margin: 20, alignItems: "stretch", justifyContent: "space-between", flex: 1}}>
                <View style={{flex: 1, marginTop: 15}}>
                    <Text variant="headlineMedium" style={{textAlign: "center"}}>
                        {welcomePageStrings.toDownload[language]}
                    </Text>
                </View>

                <FlatList
                    data={[
                        {
                            key: welcomePageStrings.stateFunds[language],
                            fileName: 'bp.pdf'
                        },
                        {
                            key: welcomePageStrings.norwayGrants[language],
                            fileName: 'nor.pdf'
                        },
                    ]}
                    renderItem={({item}) => (
                        <TouchableOpacity
                            style={{marginBottom: 10, flexDirection: 'row', justifyContent: 'center', alignItems: 'baseline', gap: 10 }}
                            onPress={async () => await Linking.openURL(require(`../assets/pdfFiles/${item.fileName}`))}>
                            <Icon source="download" size={18} />
                            <Text style={{textAlign: "center", marginBottom: 10, fontSize: 16}}>
                                {item.key}
                            </Text>

                        </TouchableOpacity>
                    )}
                />

                <View style={{flexDirection: 'row', justifyContent: 'center'}}>
                    <Button mode="contained" onPress={() => setModalVisible(false)}
                            style={{marginVertical: 5, maxWidth: 1450, flex: 1}}>
                        {welcomePageStrings.close[language]}
                    </Button>
                </View>
            </View>
        </Modal>
    </>
}
