import React from "react";
import { createContext, ReactNode, useReducer } from "react";

interface LanguageAction {
    language: "pl-PL" | "en-GB"
}

interface LanguageState {
    language?: "pl-PL" | "en-GB"
}

export const LanguageContext = createContext<{state: LanguageState, dispatch: React.Dispatch<LanguageAction>}>({ state: {}, dispatch: () => {} });

function reducer(state: LanguageState, action: LanguageAction ): LanguageState {
    return { ...state, language: action.language };
}

export function LanguageContextProvider(props: { children: ReactNode }) {
    const [state, dispatch] = useReducer(reducer, {});

    return <LanguageContext.Provider value={{ state, dispatch }}>
        {props.children}
    </LanguageContext.Provider>

}
