import {useEffect, useState} from "react";
import {View} from "react-native";
import {ActivityIndicator, Avatar, Chip, Text} from "react-native-paper";
import {TrailApi, TrailDetailsDto} from "../../api";
import Layout from "../../components/layout/layout";
import TrailMapView from "../../components/mapView/trailMapView";
import {getStringForLanguage} from "../../helpers/localizedStringHelpers";
import useApi from "../../hooks/useApi";
import useLanguage from "../../hooks/useLanguage";
import trailDetailsPageStrings from "./trailDetailsPage.strings";
import {StyleSheet} from "react-native";
import {SWTheme} from "../../styles/theme";
import EducationPointsGrid from "../../components/educationPointsGrid/educationPointsGrid";
import Bicycle from "../../components/icons/bicycle";
import Walk from "../../components/icons/walk";
import MapMarkerDistance from "../../components/icons/mapMarkerDistance";

export default function TrailDetailsPage(props: { route?: { params: { id: number } } }) {

    const {language} = useLanguage();
    const trailApi = useApi(TrailApi);
    const trailId = props.route!.params.id;
    const [trailDetails, setTrailDetails] = useState<TrailDetailsDto>();

    useEffect(() => {
        setTrailDetails(undefined);

        trailApi.apiTrailIdGet(trailId).then(r => {
            setTrailDetails(r.data);
        });
    }, [trailId]);

    return <Layout title={trailDetailsPageStrings.about[language]}>
        {trailDetails ? <>
                <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 6}}>
                    <Avatar.Icon icon={trailDetails.icon!} style={{backgroundColor: trailDetails.color!, marginRight: 12}} color="#fff" size={32}/>
                    <Text variant="headlineMedium">
                        {getStringForLanguage(trailDetails.name!, language)}
                    </Text>
                </View>
                <Text variant="bodyMedium">
                    {getStringForLanguage(trailDetails.description!, language)}
                </Text>
                <View style={styles.chipContainer}>
                    <Chip style={styles.chip}
                          icon={p => <MapMarkerDistance {...p} />}>{trailDetails.kilometerLength} km</Chip>
                    <Chip style={styles.chip} icon={p => <Walk {...p} />}>{trailDetails.walkingTime} min</Chip>
                    <Chip style={styles.chip} icon={p => <Bicycle {...p} />}>{trailDetails.cyclingTime} min</Chip>
                </View>
                <Text variant="titleLarge" style={{marginTop: 20}}>
                    {trailDetailsPageStrings.map[language]}
                </Text>
                <View style={{height: 300, width: "100%"}}>
                    <TrailMapView trail={trailDetails}/>
                </View>
                <Text variant="titleLarge" style={{marginTop: 20}}>
                    {trailDetailsPageStrings.points[language]}
                </Text>
                {trailDetails.educationPoints &&
                    <EducationPointsGrid points={trailDetails.educationPoints.sort((a, b) =>
                        a.educationPointPosition! - b.educationPointPosition!).map(p => p.educationPoint!)}
                                         trailId={trailId} trailColor={trailDetails.color}/>}
            </>
            : <ActivityIndicator/>}
    </Layout>;
}

export const styles = StyleSheet.create({
    chip: {
        backgroundColor: SWTheme.colors.primaryContainer
    },
    chipContainer: {
        marginVertical: 12,
        flexDirection: 'row',
        gap: 12,
        flexWrap: 'wrap'
    }
})
