import {TrailDto} from "../../api";
import {Card, Avatar, Button} from "react-native-paper";
import {getStringForLanguage} from "../../helpers/localizedStringHelpers";
import TrailMapView from "../mapView/trailMapView";
import {StyleSheet} from "react-native";
import useLanguage from "../../hooks/useLanguage";
import {useNavigation} from "@react-navigation/native";
import trailCardStrings from "./trailCard.strings";
import {Chip} from 'react-native-paper';
import {SWTheme} from "../../styles/theme";
import Bicycle from "../icons/bicycle";
import Walk from "../icons/walk";
import MapMarkerDistance from "../icons/mapMarkerDistance";

export function TrailCard(props: { trail: TrailDto; }) {
    const {language} = useLanguage();
    const navigation = useNavigation();

    const goToDetails = () => {
        navigation.navigate("TrailDetails" as never, {id: props.trail.id!} as never);
    }

    return <Card mode="elevated" style={styles.cardWrapper}>
        {/* <Card.Cover source={{ uri: 'https://picsum.photos/700' }} /> */}
        <Card.Title
            titleVariant="titleLarge"
            title={getStringForLanguage(props.trail.name!, language)}
            subtitleVariant="bodyMedium"
            subtitle={getStringForLanguage(props.trail.description!, language)}
            left={p => <Avatar.Icon {...p} icon={props.trail.icon!} style={{backgroundColor: props.trail.color!}} color="#fff" />}/>
        <Card.Content style={styles.chipContainer}>
            <Chip style={styles.chip} icon={p => <MapMarkerDistance {...p} />}>{props.trail.kilometerLength} km</Chip>
            <Chip style={styles.chip} icon={p => <Walk {...p} />}>{props.trail.walkingTime} min</Chip>
            <Chip style={styles.chip} icon={p => <Bicycle {...p} />}>{props.trail.cyclingTime} min</Chip>
        </Card.Content>
        <Card.Actions>
            <Button onPress={goToDetails}>{trailCardStrings.about[language]}</Button>
        </Card.Actions>
    </Card>;
}

export const styles = StyleSheet.create({
    cardWrapper: {
        marginVertical: 10
    },
    chip: {
        backgroundColor: SWTheme.colors.primaryContainer
    },
    chipContainer: {
        gap: 6,
        flexDirection: 'row',
        flexWrap: 'wrap'
    }
});
