import Constants from "expo-constants";
import { Button, Card, Chip, Text } from "react-native-paper";
import { EducationPointDto } from "../../api";
import { getStringForLanguage } from "../../helpers/localizedStringHelpers";
import { Linking, StyleSheet, View } from "react-native";
import useLanguage from "../../hooks/useLanguage";
import educationPointCardStrings from "./educationPointCard.strings";
import {NavigationProp, useNavigation} from "@react-navigation/native";
import { Image } from "react-native";
import { SWTheme } from "../../styles/theme";
import EcoIcon from "../icons/ecoIcon";
import NavigationOutline from "../icons/navigationOutline";
import MapMarkerDistance from "../icons/mapMarkerDistance";

function getCoverPictureUrl(id: number) {
    const baseUrl = Constants.expoConfig!.extra!.apiBaseUrl;

    return `${baseUrl}/api/EducationPointCoverPicture/${id}/FileStream`;
}

export default function EducationPointCardSmall(props: { point: EducationPointDto, trailId?: number , navigation?: NavigationProp<ReactNavigation.RootParamList>, trailColor?: string, onNavigated?: () => any, distance?: string}) {
    const { language } = useLanguage();
    const navigation = props.navigation ?? useNavigation();

    const openMaps = () => {
        const url = `https://www.google.com/maps/dir/?api=1&destination=${props.point.latitude},${props.point.longitude}`;

        Linking.openURL(url!);
    }

    const goToDetails = () => {

        props.onNavigated?.();

        if (props.trailId) {
            navigation!.navigate("EducationalPointTrailDetails" as never, {
                trailId: props.trailId,
                id: props.point.id!
            } as never);

            return;
        }

        navigation!.navigate("EducationalPointDetails" as never, {
            id: props.point.id!
        } as never);
    }

    return <Card style={styles.cardWrapper}>
        <Card.Content style={{ flexDirection: "row", padding: 0 }}>
            <Image style={{ width: "20%", minWidth: 100, resizeMode: "cover", borderTopLeftRadius: 6, borderBottomLeftRadius: 6 }} source={{ uri: getCoverPictureUrl(props.point.coverPictureId!) }} />
            <View style={{ padding: 16, paddingLeft: 8, flex: 1 }}>
                <View style={{ flexDirection: 'row' }}>
                    <EcoIcon height={24} width={24} color={props.trailColor ?? undefined} style={{marginRight: 2}} />
                    <Text variant="titleMedium" style={{ flexWrap: "wrap", flex: 1 }}>
                        {getStringForLanguage(props.point.name!, language)}
                    </Text>
                </View>

                {props.distance && <Chip style={styles.chip} icon={p => <MapMarkerDistance {...p} />}>{props.distance} km</Chip>}
                <View style={{ flexDirection: "row", marginTop: 8, gap: 7, justifyContent: "flex-start", flexWrap: "wrap" }}>
                    <Button mode="outlined" labelStyle={{ marginVertical: 7, marginLeft: 4 }} compact={true} onPress={openMaps} icon={() => <NavigationOutline />}>{educationPointCardStrings.navigate[language]}</Button>
                    <Button mode="contained" labelStyle={{ marginVertical: 7 }} compact={true} onPress={goToDetails}>{educationPointCardStrings.about[language]}</Button>
                </View>
            </View>
        </Card.Content>
    </Card>
}

export const styles = StyleSheet.create({
    cardWrapper: {
        paddingLeft: 0,
        marginTop: 4
    },
    chip: {
        backgroundColor: SWTheme.colors.primaryContainer,
        alignSelf: 'flex-start',
        marginTop: 10,
        marginBottom: 10
    }
});
