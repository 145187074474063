import {NavigationContainer, createNavigationContainerRef} from "@react-navigation/native";

import TrailListPage from "./pages/trailList/trailListPage";
import WelcomePage from "./pages/welcomePage";
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {AudioContextProvider} from "./contexts/audioContext";
import AudioPlayer from "./components/audioPlayer/audioPlayer";
import { Provider as PaperProvider } from 'react-native-paper';
import { SWTheme } from "./styles/theme";
import { useFonts } from "expo-font";
import { Lato_700Bold, Lato_400Regular } from "@expo-google-fonts/lato";
import { Taviraj_400Regular, Taviraj_500Medium } from "@expo-google-fonts/taviraj";
import * as SplashScreen from 'expo-splash-screen';
import { useCallback } from "react";
import { LanguageContextProvider } from "./contexts/languageContext";
import TrailDetailsPage from "./pages/trailDetails/trailDetailsPage";
import EducationalPointDetailsPage from "./pages/educationalPointDetails/educationalPointDetailsPage";
import { enableExperimentalWebImplementation } from 'react-native-gesture-handler';
import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";

const Stack = createNativeStackNavigator();
enableExperimentalWebImplementation();
export const navigationRef = createNavigationContainerRef();

export const linking = {
    prefixes: ["http://localhost:19006/"],
    config: {
        screens: {
            Welcome: "/",
            TrailList: "trails",
            TrailDetails: "trails/:id",
            EducationalPointTrailDetails: "trails/:trailId/:id",
            EducationalPointDetails: "points/:id",
            Audio: "audio",
            Map: "map"
        }
    }
}

export default function App() {
    const [fontsLoaded] = useFonts({
        Lato_700Bold,
        Lato_400Regular,
        Taviraj_400Regular,
        Taviraj_500Medium
    });

    const onLayoutRootView = useCallback(async () => {
        if (fontsLoaded) {
            await SplashScreen.hideAsync();
        }
    }, [fontsLoaded]);
    
    if (!fontsLoaded) {
        return null;
    }

    return (<AudioContextProvider>
        <LanguageContextProvider>
            <PaperProvider theme={SWTheme}>
                <SafeAreaProvider style={{flex: 1}}>
                    <NavigationContainer ref={navigationRef} theme={{ colors: { background: SWTheme.colors.background } }} linking={linking} onReady={onLayoutRootView}>
                        <Stack.Navigator screenOptions={{headerShown: false}}>
                            <Stack.Screen name={"Welcome"} options={{title: "Przystanki Naszej Historii"}} component={WelcomePage}/>
                            <Stack.Screen name={"TrailList"} options={{title: "Szlaki turystyczne"}} component={TrailListPage}/>
                            <Stack.Screen name={"TrailDetails"} options={{title: "Szlak turystyczny"}} component={TrailDetailsPage}/>
                            <Stack.Screen name={"EducationalPointTrailDetails"} options={{title: "Przystanek naszej historii"}} component={EducationalPointDetailsPage}/>
                            <Stack.Screen name={"EducationalPointDetails"} options={{title: "Przystanek naszej historii"}} component={EducationalPointDetailsPage}/>
                        </Stack.Navigator>
                    </NavigationContainer>
                    <AudioPlayer />
                </SafeAreaProvider>
            </PaperProvider>
        </LanguageContextProvider>
    </AudioContextProvider>
    );
}

serviceWorkerRegistration.register({
    onUpdate: (registration:any) => {
        const waitingServiceWorker = registration.waiting

        if (waitingServiceWorker) {
            waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
        }
    }
});
