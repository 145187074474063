import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"
import { memo } from "react"

const MuseumLogoOnly = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 135 136"
    style={{
      enableBackground: "new 0 0 135 136",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <Path
      style={{
        fill: "#815e58",
      }}
      d="M36.8 41.4c3.3 0 11.6-.2 17.7 5.8 5 5 7.2 11.1 7.2 21.4 0 13.3 4 22.8 11.1 29.8 6.2 6.1 15.2 9 24.8 9h26.2c1 0 1.9-.8 1.9-1.9l.1-93.5c0-1-.8-1.9-1.9-1.9H11c-1 0-1.9.8-1.9 1.9v7.9c0 1 .8 1.9 1.9 1.9h103.1v74H97.7c-3.4 0-11.5 0-17.5-5.9-5-5-7.2-11.1-7.2-21.4 0-13.3-4-22.8-11.1-29.8-6.2-6.1-15.2-9-24.8-9H10.9c-1 0-1.9.8-1.9 1.9V125c0 1 .8 1.9 1.9 1.9h112.8c1 0 1.9-.8 1.9-1.9v-7.9c0-1-.8-1.9-1.9-1.9h-103l-.1-74h16.2z"
    />
  </Svg>
)

const Memo = memo(MuseumLogoOnly)
export default Memo
